@use 'styles' as theme;

.root {
  display: flex;
  width: 100%;
  justify-content: center;
  transform: translateY(-50%);
}

.wrapper {
  --borderColor: #{theme.$colorDarkBlue};

  display: flex;
  align-items: center;
  padding: theme.size(1);
  border: 2px solid var(--borderColor);
  border-radius: theme.$borderRadiusPill;
  background-color: theme.$colorLightBlue200;
}

.icon {
  color: theme.$colorBlue;
}

.textWrapper {
  padding: theme.size(0 1);
}

.highlighted {
  --borderColor: #{theme.$colorBlue};
}
