@use 'styles' as theme;

.root {
  display: inline-block;
  width: 100%;
  margin-bottom: theme.size(1);
  cursor: pointer;
}

.hiddenCanvas {
  display: none;
}
