@use 'styles' as theme;

.arrowRight {
  right: theme.size(5);
}

.arrowLeft {
  left: theme.size(5);
}

.pswp {
  img {
    max-width: none;
    object-fit: contain;
  }
}
