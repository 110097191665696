@use 'styles' as theme;

.icon {
  @include theme.circle(theme.size(10));

  background-color: theme.$colorBlue;
  color: theme.$colorWhite;
}

.text {
  flex: 1;
}
