@use 'styles' as theme;

.image {
  width: 100%;
  height: auto;
}

.icon {
  color: theme.$colorBlue;
}
