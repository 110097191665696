@use 'styles' as theme;

.root {
  position: relative;
  width: 100%;
  max-width: theme.size(35);
  margin: 0;
}

.wrapper {
  --borderColor: #{theme.$colorDarkBlue};

  overflow: hidden;
  border: 2px solid var(--borderColor);
  border-radius: theme.$borderRadiusXL;
}

.highlighted {
  --borderColor: #{theme.$colorBlue};
}

.mainPhoto {
  position: relative;
  padding: theme.size(3);
}

.photoWrapper {
  position: relative;
  display: flex;
}

.caption {
  height: 0;
}

.audi {
  width: 100%;
  height: auto;
  border-radius: theme.$borderRadiusL;
}

.content {
  flex: 1;
  border-top: 2px solid var(--borderColor);
  background-color: theme.$colorWhite;
}

.list {
  @include theme.unstyledList;

  padding: theme.size(2 1 3);
}

.item {
  padding: theme.size(1 2);

  &:last-child {
    @include theme.hideUntilTabletLandscape;
  }

  &:not(:first-child) {
    border-top: 1px solid theme.$colorGrey300;
  }
}

.image {
  border-radius: theme.$borderRadiusXXS;
}

.placeholder {
  height: theme.size(0.5);
  border-radius: theme.$borderRadiusPill;
  background-color: theme.$colorGrey200;
}

.widePlaceholder {
  width: 80%;
}

.narrowPlaceholder {
  width: 60%;
}

.text {
  flex: 1;
}

.footer {
  padding: theme.size(2);
  background-color: theme.$colorBlue;
}

.watermarkMain {
  position: absolute;
  right: theme.size(1);
  bottom: theme.size(1);
}

.watermarkMini {
  position: absolute;
  right: theme.size(0.5);
  bottom: theme.size(0.5);
}
