@use 'styles' as theme;

.wrapper {
  width: 100%;
  max-width: theme.size(65);
  border: 1px dashed theme.$colorBlue;
  border-radius: theme.$borderRadiusXS;
  margin: 0 auto;
  background-color: theme.$colorWhite;
}

.buttonWrapper {
  box-sizing: border-box;
  padding: theme.size(0 2 2);
}
