@use 'styles' as theme;

.masonry {
  width: 100%;
  flex: 1;
  column-gap: theme.size(1);
  columns: 3 theme.size(50);
}

.fab {
  @include theme.hideFromTablet;

  right: theme.size(1);
  bottom: theme.size(1);
}
